import { SimplePanel } from '@/apps/features/map/info-panel/panel-data/simple-panel';
import { LAYER_KEY__EXTERNAL_PROJECT } from '@/apps/usecase-2/map/layer-config/external-project';
import { LAYER_KEY__ENVIRONMENT } from '@/apps/features/map/layer-config/environment';
import {
  LAYER_KEY__ESTATE,
  LAYER_KEY__RESTRICTION,
  LAYER_KEY__GRID_STATIONS_SUBSTATION,
  LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
} from '@/apps/features/map/layer-config/base-data';
import { LAYER_KEY__BUILDINGS } from '@/apps/features/map/layer-config/building';
import {
  LAYER_KEY__GENERATION_PV_FREE,
  LAYER_KEY__GENERATION_WIND,
} from '@/apps/features/map/layer-config/existing-power-plants';
import { LAYER_KEY__PV_PROJECT } from '@/apps/usecase-2/map/layer-config/potential-analysis';

export const panelObjects = [
  new SimplePanel('/api/usecase-2/external-project/', [
    LAYER_KEY__EXTERNAL_PROJECT,
  ]),
  new SimplePanel('/api/usecase-2/environmental-data/', [
    LAYER_KEY__ENVIRONMENT,
  ]),
  new SimplePanel('/api/basemodule/building/', [LAYER_KEY__BUILDINGS]),
  new SimplePanel('/api/basemodule/estate/', [LAYER_KEY__ESTATE]),
  new SimplePanel('/api/usecase-2/restriction/', [LAYER_KEY__RESTRICTION]),
  new SimplePanel('/api/basemodule/existing-power-plant-pv-free/', [
    LAYER_KEY__GENERATION_PV_FREE,
  ]),
  new SimplePanel('/api/basemodule/existing-power-plant-wind/', [
    LAYER_KEY__GENERATION_WIND,
  ]),
  new SimplePanel('/api/usecase-2/pv-project-simple/', [LAYER_KEY__PV_PROJECT]),
  new SimplePanel('/api/basemodule/grid-station/', [
    LAYER_KEY__GRID_STATIONS_SUBSTATION,
    LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
  ]),
];

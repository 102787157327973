<template>
  <div class="flex flex-col gap-5 p-1">
    <div
      v-for="item in props.data"
      :key="item.id"
      class="standard-elevation-0-dark rounded-[4px] p-2.5 flex items-center justify-between gap-2.5"
    >
      <IconWrapper
        :size="48"
        :icon="IconFileCopy"
        fill="text-color1"
        class="mb-auto"
      />

      <div v-if="item.description" class="flex flex-col gap-2.5 mr-auto">
        <h6 class="text-color1 leading-normal">{{ item.title }}</h6>
        <div class="body-3 text-neutral leading-normal">
          {{ item.description }}
        </div>
      </div>
      <h5 v-else class="text-color1 leading-normal mr-auto">
        {{ item.title }}
      </h5>

      <ButtonEl
        :text="buttonText"
        :is-loading="isLoading"
        :icon-right="true"
        icon="download"
        @click="emit('download', item)"
      />
    </div>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconFileCopy from '@/assets/icons/custom/misc/IconFileCopy.vue';

const props = defineProps({
  data: {
    type: Array,
    default: null,
  },
  buttonText: {
    type: String,
    default: 'Download',
  },
  isLoading: {
    type: Boolean,
    defualt: false,
  },
});

const emit = defineEmits(['download']);
</script>

import axios from 'axios';

export const useBaseMapStore = {
  state: () => ({
    showInfoPanel: false,
    showSlider: false,
    year: null,
    FlyTo: {
      zoom: 12,
      lng: 10.4515,
      lat: 51.1656,
      essential: true,
      maxZoom: 16.9,
    },
    selectedAnalysis: null,
    layerPanelConfig: {},
    layerConfigs: {},
    metaDataLayers: {},
    filters: {},
    satelliteIsActive: false,
    refreshFeature: false,
    aggregationLevel: null,
    aggregationStyle: 'default',
    customMapConfig: [],
    activeLayerToggleSections: {},
    mapControlsHeight: 0,
    showMainMenu: false,
    showAnalysisSelector: true,
    searchResults: null,
    showSearch: false,
    mapActions: {},
    drawModeActive: 'simple_select',
    drawFeatures: [],
  }),
  actions: {
    /**
     * always keep draw features in Store for better control and checks
     * (i.e. validation)
     * @param {Array} features - draw features draw.getAll()
     */
    updateDrawFeatures(features) {
      this.drawFeatures = features;
    },
    /**
     * Updates the visibility of map layers based on the provided payload.
     *
     * @param {Object} payload - The data used to update layer visibility.
     * @param {Array<string>} [payload.unselect] - List of layer names to hide (set visibility to false).
     * @param {Array<string>} payload.onLayers - List of layer names to update visibility.
     * @param {boolean} payload.active - Visibility state to apply to the layers in `onLayers`.
     */
    changeLayerVisibility(payload) {
      if (Array.isArray(payload.unselect)) {
        payload.unselect.forEach((layerName) => {
          const layerConfig = this.layerConfigs[layerName];
          if (layerConfig) {
            layerConfig.visible = false;
          }
        });
      }
      payload.onLayers.forEach((i) => {
        if (this.layerConfigs[i] !== undefined) {
          this.layerConfigs[i].visible = payload.active;
        } else {
          console.warn(`Layer config for index ${i} is undefined.`);
        }
      });
    },

    changeLayerState(payload) {
      payload.layerKeys.forEach((i) => {
        this.layerConfigs[i].layoutState = payload.layerState;
      });
    },
    /**
     * Adds a filter to specified layers.
     *
     * @param {Array<string>} layerKeys - An array of layer keys where the filter should be applied.
     * @param {Object} filter - The filter configuration to add.
     * @param {string} filterId - The unique identifier for the filter.
     */
    addFilter(layerKeys, filter, filterId) {
      const filters = { ...this.filters };
      layerKeys.forEach((i) => {
        let filterConfig = filters[i] || {};
        filterConfig[filterId] = filter;
        filters[i] = filterConfig;
      });
      this.filters = filters;
    },
    /**
     * Removes a filter from specified layers.
     *
     * @param {Array<string>} layerKeys - An array of layer keys from which the filter should be removed.
     * @param {string} filterId - The unique identifier of the filter to remove.
     */
    removeFilter(layerKeys, filterId) {
      const filters = { ...this.filters };
      layerKeys.forEach((i) => {
        delete filters[i][filterId];
      });
      this.filters = filters;
    },
    setShowInfoPanel(show) {
      this.showInfoPanel = show;
    },
    showYearSlider(show) {
      this.showSlider = show;
    },
    setYear(year) {
      this.year = year;
    },
    toggleSatellite() {
      this.satelliteIsActive = !this.satelliteIsActive;
    },
    setCoordinates: (state, payload) => {
      const zoom = payload.zoom || 12;
      state.FlyTo.lng = payload.lng;
      state.FlyTo.lat = payload.lat;
      state.FlyTo.zoom = zoom;
    },
    setInfoPanelVisibility(show) {
      this.showInfoPanel = show;
    },
    setLayerMetaData(layerId, metaData) {
      this.metaDataLayers[layerId] = metaData;
    },
    setAggregationStyle(style) {
      this.aggregationStyle = style;
    },
    setActiveToggle(layerState, toggleId, active) {
      if (typeof this.activeLayerToggleSections[layerState] === 'undefined') {
        this.activeLayerToggleSections[layerState] = [];
      }
      if (active) this.activeLayerToggleSections[layerState].push(toggleId);
      else {
        const index = this.activeLayerToggleSections[layerState].findIndex(
          (e) => e === toggleId,
        );
        this.activeLayerToggleSections[layerState].splice(index, 1);
      }
    },
    /**
     * change default layers and states on next map navigation
     * @param  {array} config Array of Obj containing layer and state
     */
    configureInitialMapState(config) {
      this.customMapConfig = config;
    },
    setMapControlsHeight(height) {
      this.mapControlsHeight = height;
    },
    toggleMainMenu() {
      this.showMainMenu = !this.showMainMenu;
    },
    toggleAnalysisSelector() {
      this.showAnalysisSelector = !this.showAnalysisSelector;
    },
    setSearchbar(show) {
      this.showSearch = show;
    },
    resetSearch() {
      this.searchResults = null;
      this.showSearch = false;
    },
    fetchSearchResults(input) {
      return new Promise((resolve, reject) => {
        axios({
          url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=pk.eyJ1IjoibWFwZnJlZWxiZCIsImEiOiJjandzem95eW8wa2FhM3lxdjdxdjNlbjliIn0.JVEcWEoquKW1_4p6hBPTNg&cachebuster=1621432191888&autocomplete=true&country=de&language=de`,
          method: 'GET',
        })
          .then((resp) => {
            if (resp.data.features) {
              this.searchResults = resp.data.features;
            } else {
              this.searchResults = resp;
            }
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changeDrawMode(payload) {
      this.drawModeActive = payload;
    },
  },
  getters: {
    getLegendEntries: (state) => {
      const vis = [];
      Object.entries(state.layerConfigs).forEach((item) => {
        const e = item[1];
        if (e.visible && e.legend) {
          const legend = e.legend[e.layoutState];
          if (legend) vis.push(legend);
        }
      });
      return vis;
    },
    getLayerStates: (state) =>
      Object.keys(state.layerConfigs).map((e) => ({
        layer: e,
        state: state.layerConfigs[e].layoutState,
        visible: state.layerConfigs[e].visible,
      })),
    getLayersToClick: (state) => {
      const layers = [];
      Object.entries(state.layerConfigs).forEach((item) => {
        if (item[1].sidebar || item[1].onClick) {
          layers.push(item[0]);
        }
      });
      return layers;
    },
    getLayers: (state) => {
      return Object.keys(state.layerConfigs);
    },
    getLayersVisible: (state) => {
      const layersVisible = [];
      for (const [key, value] of Object.entries(state.layerConfigs)) {
        if (value.visible) layersVisible.push(key);
      }
      return layersVisible;
    },
    getAppliedFilters: (state) => {
      const appliedFilters = {};
      for (const layer of Object.keys(state.filters)) {
        const filter = Object.values(state.filters[layer]);
        appliedFilters[layer] = null;
        if (filter.length === 1) appliedFilters[layer] = filter[0];
        if (filter.length > 1) appliedFilters[layer] = ['all', ...filter];
      }
      return appliedFilters;
    },
  },
};

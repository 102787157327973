export const colorGridPlanningStatus = (value) => {
  switch (value) {
    case 'Netzanschluss erfolgreich: Anschlussleistung <= Verfügbare Kapazität Anschlusspunkt':
      return 'text-spot-success bg-fill-success';
    case 'Netzanschluss erfolgreich: Anschlussleistung <= Max. definierte Anschlussleistung HS-Trasse':
      return 'text-spot-success bg-fill-success';
    case 'Netzanschluss eingeschränkt erfolgreich: Anschlussleistung > Verfügbare Kapazität Anschlusspunkt - es wurde der nächstgelegene Anschlusspunkt mit maximal-verfügbarer Kapazität gewählt':
      return 'text-spot-warning bg-fill-warning';
  }
  return 'text-spot-error bg-fill-error';
};

export const colorProjectStatus = (reviewState, text) => {
  const styleConfig = {
    color: '',
    bgColor: '',
    icon: '',
    text,
  };
  switch (reviewState) {
    case 'TODO':
      styleConfig.color = 'text-spot-warning';
      styleConfig.bgColor = 'hover:bg-fill-warning';
      styleConfig.icon = 'pending_actions';
      break;
    case 'YES':
      styleConfig.color = 'text-spot-success';
      styleConfig.bgColor = 'hover:bg-fill-success';
      styleConfig.icon = 'check';
      break;
    case 'NO':
      styleConfig.color = 'text-spot-error';
      styleConfig.bgColor = 'hover:bg-fill-error';
      styleConfig.icon = 'cancel';
      break;
  }
  return styleConfig;
};

export function navigateToMap(router, analysisId, projectId = null) {
  const option = {
    name: 'mapView2',
    params: { analysisId },
  };
  if (projectId) option.query = { projectId };
  router.push(option);
}

export const valueToChoice = (value, option) => {
  if (typeof value === 'undefined' || value === null) {
    return '-';
  } else {
    try {
      return option.choices.find((e) => e.value === value).display_name;
    } catch (e) {
      throw Error(
        `choicefield ${value} not in meta options: ${option.choices}`,
      );
    }
  }
};

import {
  LIND_GREEN_60,
  AZUR_BLUE_40,
  AZUR_BLUE_70,
  LIND_GREEN_10,
  LIND_GREEN_70,
} from '@/configs/color-constants';
import {
  gradientLayerStyle,
  gradientLegend,
  polygonClickEnabledStyle,
} from '@/apps/features/map/utils';
export const LAYER_KEY__CLUSTER_PV_PROJECT = 'cluster_project';
export const LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION =
  'cluster_projectconnection';
export const LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT =
  'cluster_hv_connection_point';

export default {
  [LAYER_KEY__CLUSTER_PV_PROJECT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__CLUSTER_PV_PROJECT,
      sourceLayer: LAYER_KEY__CLUSTER_PV_PROJECT,
      layerId: LAYER_KEY__CLUSTER_PV_PROJECT,
      visibility: 'none',
      tilesDirectory: 'uc2',
    },
    style: {
      default: polygonClickEnabledStyle([
        ['boolean', ['feature-state', 'marked'], false],
        LIND_GREEN_60,
        AZUR_BLUE_70,
      ]),
      area: polygonClickEnabledStyle([
        ['boolean', ['feature-state', 'marked'], false],
        LIND_GREEN_60,
        ...gradientLayerStyle(
          'potential_area_m2',
          { value: 0, color: LIND_GREEN_10 },
          { value: 1000000, color: LIND_GREEN_70 },
        ),
      ]),
      power: polygonClickEnabledStyle([
        ['boolean', ['feature-state', 'marked'], false],
        LIND_GREEN_60,
        ...gradientLayerStyle(
          'power_kw',
          { value: 0, color: LIND_GREEN_10 },
          { value: 150000, color: LIND_GREEN_70 },
        ),
      ]),
      invest: polygonClickEnabledStyle([
        ['boolean', ['feature-state', 'marked'], false],
        LIND_GREEN_60,
        ...gradientLayerStyle(
          'investment_cost_pv_eur',
          { value: 0, color: LIND_GREEN_10 },
          { value: 100000000, color: LIND_GREEN_70 },
        ),
      ]),
    },
    legend: {
      default: {
        name: 'Projekt-Cluster',
        unit: '',
        items: [
          {
            color: AZUR_BLUE_70,
            name: 'Potenzialfläche',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      area: gradientLegend(
        'Potenzialfläche',
        'ha',
        { value: 0, color: LIND_GREEN_10 },
        { value: 100, color: LIND_GREEN_70 },
      ),
      power: gradientLegend(
        'Potenzialfläche Leistung',
        'MW',
        { value: 0, color: LIND_GREEN_10 },
        { value: 150, color: LIND_GREEN_70 },
      ),
      invest: gradientLegend(
        'Potenzialfläche Investitionskosten',
        'Mio. €',
        { value: 0, color: LIND_GREEN_10 },
        { value: 100, color: LIND_GREEN_70 },
      ),
    },
  },
  [LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION,
      sourceLayer: LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION,
      layerId: LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION,
      visibility: 'none',
      tilesDirectory: 'uc2',
    },
    style: {
      default: {
        lineColor: AZUR_BLUE_40,
        lineWidth: 2,
      },
    },
    legend: {
      default: {
        name: 'Projekt-Cluster',
        unit: '',
        items: [
          {
            color: AZUR_BLUE_40,
            name: 'Anschlussleitung',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
  [LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT,
      sourceLayer: LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT,
      layerId: LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT,
      visibility: 'none',
      tilesDirectory: 'uc2',
    },
    style: {
      default: {
        circleColor: AZUR_BLUE_40,
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 8,
      },
    },
    legend: {
      default: {
        name: 'Projekt-Cluster',
        unit: '',
        items: [
          {
            color: AZUR_BLUE_40,
            name: 'HS-Anschluss',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};

<template>
  <div
    class="pade_subnavi outline-none side-bar-custom hide-scrollbar nav-drawer bg-white rounded-[8px] left-5 top-[80px] h-fit w-[360px] absolute z-10 standard-elevation-2 pt-2.5 overflow-y-auto"
    :style="{ maxHeight: containerHeight - 160 + 'px' }"
  >
    <div class="flex items-center justify-between p-2.5 pt-0 gap-2">
      <h5 class="text-title-neutral select-none">Kartenansicht</h5>
      <slot name="header" />
    </div>
    <div
      v-for="(section, index) in layerPanelConfig.sections"
      v-show="section.show"
      :key="index"
      class="flex-col layer-panel-section"
      :class="{ flex: section.show }"
    >
      <h5
        v-if="section.title"
        class="text-title-color1 p-2.5 border-b border-line-disabled-neutral select-none"
      >
        {{ section.title }}
      </h5>

      <LayerPanelItem
        v-for="(group, groupIndex) in section.groups"
        :key="groupIndex"
        :menu-item="group"
        class="nav-menu-item"
        :open="open[0]"
        :map-store
        @emit-activator="setOpenedActivator($event)"
      >
        <template v-if="group.items">
          <LayerPanelItem
            v-for="(item, itemIndex) in group.items"
            :key="itemIndex"
            :data-test="`item-group-${item.title}`"
            :menu-item="item"
            :parent-id="group.id"
            :open="open[1]"
            subgroup
            small
            :item-level="1"
            :map-store
            @emit-activator="setOpenedActivator($event)"
          >
            <template v-if="item.items">
              <LayerPanelItem
                v-for="(level3Item, level3Index) in item.items"
                :key="level3Index"
                :menu-item="level3Item"
                :parent-id="item.id"
                :grand-parent-id="group.id"
                :open="open[2]"
                small
                :item-level="2"
                :map-store
                @emit-activator="setOpenedActivator($event)"
              >
                <template #layer-toggles="slotProps">
                  <slot name="layer-toggles" v-bind="slotProps" />
                </template>
              </LayerPanelItem>
            </template>
            <template #layer-toggles="slotProps">
              <slot name="layer-toggles" v-bind="slotProps" />
            </template>
          </LayerPanelItem>
        </template>
        <template #layer-toggles="slotProps">
          <slot name="layer-toggles" v-bind="slotProps" />
        </template>
      </LayerPanelItem>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import LayerPanelItem from '@/apps/features/map/layer-panel/LayerPanelItem.vue';
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { height: containerHeight } = useWindowSize();

const open = ref([null, null, null]); // Array to store open state for levels 0, 1, and 2

function setOpenedActivator({ title, level }) {
  open.value = open.value.map((item, idx) =>
    idx === level ? (item === title ? null : title) : item,
  );
}

const layerPanelConfig = computed(() => {
  return props.mapStore.layerPanelConfig;
});
</script>

<style lang="scss">
.layer-panel-section {
  .filter_group.nav-menu-item {
    @apply border-b border-line-disabled-neutral;
    .filter_group {
      &:not(:last-child),
      &.not-1st-level {
        @apply border-b border-line-disabled-neutral;
      }

      &:first-child {
        @apply border-t border-line-disabled-neutral;
      }
    }

    .filter_group:last-child .switchery-layer-custom .switch-list-item {
      &.not-1st-level:last-child {
        @apply border-none;
      }
    }

    .filter_group:not(:last-child) .switchery-layer-custom {
      &.apply-border {
        @apply border-white border-b;
      }
    }
  }

  &:last-child .filter_group.nav-menu-item:last-child {
    @apply border-none;
  }
}
</style>

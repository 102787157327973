import {
  DEEP_BLUE_LIGHT,
  IMPULSE_ORANGE_MEDIUM,
  LIND_GREEN_60,
  NEUTRAL_GREY_60,
  SUN_YELLOW_40,
  TAROCCO_60,
  SUN_YELLOW_30,
  LIND_GREEN_10,
  LIND_GREEN_70,
} from '@/configs/color-constants';
import {
  gradientLayerStyle,
  gradientLegend,
  polygonClickEnabledStyle,
} from '@/apps/features/map/utils';
import { colorGridPlanningStatus } from '@/apps/usecase-2/utils';
export const LAYER_KEY__PV_PROJECT = 'pv_project';
export const LAYER_KEY__PV_PROJECT_CONNECTION = 'projectconnection';
export const LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT =
  'grid_connection_point';
export const LAYER_KEY__PV_PROJECT_SUBSTATION_POINT = 'substation_point';
export const LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT = 'hv_connection_point';

export default {
  [LAYER_KEY__PV_PROJECT]: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__PV_PROJECT,
      sourceLayer: LAYER_KEY__PV_PROJECT,
      layerId: LAYER_KEY__PV_PROJECT,
      visibility: 'visible',
      tilesDirectory: 'uc2',
    },
    sidebar: {
      name: 'Projektgebiet',
      subtitles: [
        'id',
        'name',
        'analysis_title',
        'review_state',
        'is_favorite',
      ],
      chips: [
        {
          title: 'Fläche',
          active: true,
          items: [
            {
              title: 'Fläche',
              active: true,
              items: [
                {
                  key: 'potential_area_m2',
                  decimal: 0,
                },
                {
                  key: 'estates_area_ha',
                  decimal: 0,
                },
                {
                  key: 'count_estates',
                  decimal: 0,
                },
              ],
            },
            {
              title: 'Topographie',
              active: true,
              items: [
                {
                  key: 'average_altitude_m',
                  decimal: 0,
                },
                {
                  key: 'min_altitude_m',
                  decimal: 0,
                },
                {
                  key: 'max_altitude_m',
                  decimal: 0,
                },
                {
                  key: 'average_slope_degree',
                  decimal: 0,
                },
                {
                  key: 'orientation_range',
                },
              ],
            },
            {
              title: 'Formfaktor',
              active: true,
              items: [
                {
                  key: 'bbox_height_m',
                  decimal: 0,
                },
                {
                  key: 'bbox_width_m',
                  decimal: 0,
                },
                {
                  key: 'share_potential_area_in_bbox_percent',
                  decimal: 0,
                  multiply: 100,
                },
              ],
            },
          ],
        },
        {
          title: 'Technische Auslegung',
          active: false,
          items: [
            {
              title: 'Anlage',
              active: true,
              items: [
                {
                  key: 'globalradiation_kwh_m2',
                  decimal: 0,
                },
                {
                  key: 'operating_hours_h_a',
                  decimal: 0,
                },
                {
                  key: 'power_kw',
                  decimal: 0,
                },
                {
                  key: 'production_kwh_a',
                  decimal: 0,
                },
              ],
            },
            {
              title: 'Netzanschluss',
              active: true,
              items: [
                {
                  key: 'connection_status_with_battery',
                  color: (value) => colorGridPlanningStatus(value),
                },
                {
                  key: 'connection_status_without_battery',
                  color: (value) => colorGridPlanningStatus(value),
                },
                {
                  key: 'battery',
                },
                {
                  key: 'voltage_level',
                  decimal: 0,
                },
                {
                  key: 'grid_point_max_power_kw',
                  decimal: 0,
                },
                {
                  key: 'battery_capacity_kw',
                  decimal: 1,
                },
                {
                  key: 'installed_power_mw',
                  decimal: 1,
                },
                {
                  key: 'length_m',
                  decimal: 0,
                },
              ],
            },
          ],
        },
        {
          title: 'Investitionen',
          active: false,
          items: [
            {
              title: 'Übersicht Gesamt',
              active: true,
              items: [
                {
                  key: 'investment_cost_total_eur',
                  decimal: 2,
                },
                {
                  key: 'investment_cost_pv_eur',
                  decimal: 2,
                },
                {
                  key: 'investment_cost_battery_eur',
                  decimal: 2,
                },
                {
                  key: 'investment_cost_grid_eur',
                  decimal: 2,
                },
              ],
            },
            {
              title: 'Übersicht Netzanschluss',
              active: true,
              items: [
                {
                  key: 'investment_grid_eur',
                  decimal: 2,
                },
                {
                  key: 'investment_cost_cable_eur',
                  decimal: 2,
                },
                {
                  key: 'investment_cost_underground_eur',
                  decimal: 2,
                },
                {
                  key: 'investment_cost_hv_connection_eur',
                  decimal: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: polygonClickEnabledStyle([
        ['boolean', ['feature-state', 'marked'], false],
        LIND_GREEN_60,
        SUN_YELLOW_30,
      ]),
      area: polygonClickEnabledStyle([
        ['boolean', ['feature-state', 'marked'], false],
        LIND_GREEN_60,
        ...gradientLayerStyle(
          'potential_area_m2',
          { value: 0, color: LIND_GREEN_10 },
          { value: 1000000, color: LIND_GREEN_70 },
        ),
      ]),
      power: polygonClickEnabledStyle([
        ['boolean', ['feature-state', 'marked'], false],
        LIND_GREEN_60,
        ...gradientLayerStyle(
          'power_kw',
          { value: 0, color: LIND_GREEN_10 },
          { value: 150000, color: LIND_GREEN_70 },
        ),
      ]),
      invest: polygonClickEnabledStyle([
        ['boolean', ['feature-state', 'marked'], false],
        LIND_GREEN_60,
        ...gradientLayerStyle(
          'investment_cost_pv_eur',
          { value: 0, color: LIND_GREEN_10 },
          { value: 100000000, color: LIND_GREEN_70 },
        ),
      ]),
    },
    legend: {
      default: {
        name: 'Potenzialanalyse',
        unit: '',
        items: [
          {
            color: SUN_YELLOW_30,
            name: 'Potenzialfläche',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      area: gradientLegend(
        'Potenzialfläche',
        'ha',
        { value: 0, color: LIND_GREEN_10 },
        { value: 100, color: LIND_GREEN_70 },
      ),
      power: gradientLegend(
        'Potenzialfläche Leistung',
        'MW',
        { value: 0, color: LIND_GREEN_10 },
        { value: 150, color: LIND_GREEN_70 },
      ),
      invest: gradientLegend(
        'Potenzialfläche Investitionskosten',
        'Mio. €',
        { value: 0, color: LIND_GREEN_10 },
        { value: 100, color: LIND_GREEN_70 },
      ),
    },
  },
  [LAYER_KEY__PV_PROJECT_CONNECTION]: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__PV_PROJECT_CONNECTION,
      sourceLayer: LAYER_KEY__PV_PROJECT_CONNECTION,
      layerId: LAYER_KEY__PV_PROJECT_CONNECTION,
      visibility: 'visible',
      tilesDirectory: 'uc2',
    },
    style: {
      default: {
        lineColor: SUN_YELLOW_40,
        lineWidth: 2,
      },
    },
    legend: {
      default: {
        name: 'Potenzialanalyse',
        unit: '',
        items: [
          {
            color: SUN_YELLOW_40,
            name: 'Anschlussleitung',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
  [LAYER_KEY__PV_PROJECT_SUBSTATION_POINT]: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
      sourceLayer: LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
      layerId: LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
      visibility: 'visible',
      tilesDirectory: 'uc2',
    },
    style: {
      default: {
        circleColor: IMPULSE_ORANGE_MEDIUM,
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 8,
      },
    },
    legend: {
      default: {
        name: 'Potenzialanalyse',
        unit: '',
        items: [
          {
            color: IMPULSE_ORANGE_MEDIUM,
            name: 'Umspannwerk',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
  [LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT]: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
      sourceLayer: LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
      layerId: LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
      visibility: 'visible',
      tilesDirectory: 'uc2',
    },
    style: {
      default: {
        circleColor: DEEP_BLUE_LIGHT,
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 8,
      },
    },
    legend: {
      default: {
        name: 'Potenzialanalyse',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_LIGHT,
            name: 'Netzverknüpfungspunkt',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
  [LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT]: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
      sourceLayer: LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
      layerId: LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
      visibility: 'visible',
      tilesDirectory: 'uc2',
    },
    style: {
      default: {
        circleColor: SUN_YELLOW_40,
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 8,
      },
    },
    legend: {
      default: {
        name: 'Potenzialanalyse',
        unit: '',
        items: [
          {
            color: SUN_YELLOW_40,
            name: 'HS-Netzverknüpfungspunkt',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};

import { ref, markRaw, computed, toRefs } from 'vue';
import IconLineCalc from '@/assets/icons/custom/energy-grid/IconLineCalc.vue';

export const useResultOverviewData = (resultsStore) => {
  const {
    projectAggregationData: aggData,
    activeResultType,
    projectData,
    clusterData,
  } = toRefs(resultsStore);

  const projectHeaders = ref([
    {
      title: 'Projektname',
      key: 'name',
      align: 'left',
      width: '250px',
      sortable: false,
    },
    {
      title: 'Analysetyp',
      key: 'is_type',
      align: 'left',
      sortable: false,
    },
    {
      title: 'Gemeinde, Landkreis etc.',
      key: 'municipalities',
      align: 'left',
      width: '265px',
      sortable: false,
    },
    {
      title: 'Fläche',
      key: 'potential_area_m2',
      align: 'end',
      width: '100px',
      sortable: false,
    },
    {
      title: 'Flur-\nstücke',
      key: 'count_estates',
      align: 'end',
      width: '120px',
      sortable: false,
    },
    {
      title: 'Leistung\nPV',
      key: 'power_kw',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Länge Netz\nanschluss',
      key: 'connection_length_m',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Leistung\nBatteriesp.',
      key: 'battery_capacity_kw',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Spezifische\nInvestition',
      key: 'specific_investment_eur_kw',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Spannungs-\nebene',
      key: 'voltage_level',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Investitions-\nvolumen',
      key: 'total_investment_cost_eur',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Wert-\nhaltigkeit',
      key: 'utility_value',
      align: 'center',
      width: '120px',
      sortable: false,
    },
    {
      title: '',
      key: 'review_state',
      width: '60px',
      align: 'center',
      sortable: false,
    },
    {
      title: '',
      key: 'is_favorite',
      width: '60px',
      align: 'center',
      sortable: false,
    },
    {
      title: '',
      key: 'functions',
      width: '160px',
      align: 'end',
      sortable: false,
    },
  ]);

  const clusterHeaders = ref([
    {
      title: 'Cluster',
      key: 'name',
      align: 'left',
      width: '250px',
      sortable: false,
    },
    {
      title: 'Anzahl\nEinzelprojekte',
      key: 'count_projects_enbw',
      align: 'left',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Gemeinde, Landkreis etc.',
      key: 'municipalities',
      align: 'left',
      width: '265px',
      sortable: false,
    },
    {
      title: 'Fläche',
      key: 'area_enbw_m2',
      align: 'end',
      width: '100px',
      sortable: false,
    },
    {
      title: 'Flur-\nstücke',
      key: 'count_estates_enbw',
      align: 'end',
      width: '120px',
      sortable: false,
    },
    {
      title: 'Leistung\nPV',
      key: 'power_enbw_kw"',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Länge Netz\nanschluss',
      key: 'connection_length_enbw_m',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Leistung\nBatteriesp.',
      key: 'battery_power_enbw_kw',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Spezifische\nInvestition',
      key: 'specific_investment_enbw_eur_kw"',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Investitions-\nvolumen',
      key: 'investment_cost_enbw_eur',
      align: 'end',
      width: '150px',
      sortable: false,
    },
    {
      title: '',
      key: 'review_state',
      width: '80px',
      align: 'center',
      sortable: false,
    },
    {
      title: '',
      key: 'functions',
      width: '160px',
      align: 'end',
      sortable: false,
    },
  ]);

  const tabs = ref([
    {
      title: 'Einzelprojekte',
      icon: 'all_out',
      id: 'pv-project',
    },
    {
      title: 'Cluster',
      icon: 'bubble_chart',
      id: 'cluster',
    },
  ]);

  const config = ref([
    [
      {
        label: 'Anzahl Cluster',
        value: 9,
        icon: 'bubble_chart',
        tab: 'cluster',
      },
      {
        label: 'Anzahl Einzelprojekte',
        value: computed(() => aggData.value.count_projects),
        icon: 'flip_to_front',
        tab: 'cluster',
      },
    ],
    [
      {
        label: 'Gesamtfläche (ha)',
        value: computed(() => aggData.value.area_m2),
        icon: 'select_all',
      },
      {
        label: 'Anzahl betroffener Flurstücke',
        value: computed(() => aggData.value.count_estates),
        icon: 'layers',
      },
    ],

    [
      {
        label: 'Installierbare Leistung PV (MW)',
        value: computed(() => aggData.value.power_kw),
        icon: 'solar_power',
        maxDigits: 0,
      },
    ],

    [
      {
        label: 'Investitionskosten Netzanschluss (Mrd. €)',
        value: computed(
          () =>
            (aggData.value.investment_cost_hv_connection_eur +
              aggData.value.investment_cost_cable_eur +
              aggData.value.investment_cost_underground_eur) /
            1000,
        ),
        icon: markRaw(IconLineCalc),
      },
      {
        label: 'Investitionskosten Anlage (Mrd. €)',
        value: computed(
          () =>
            (aggData.value.investment_cost_pv_eur +
              aggData.value.investment_cost_battery_eur) /
            1000,
        ),
        icon: 'euro',
      },
      {
        label: 'Investitionskosten (Mrd. €)',
        value: computed(
          () =>
            Object.keys(aggData.value)
              .filter((key) => key.startsWith('investment_'))
              .reduce((sum, key) => sum + aggData.value[key], 0) / 1000,
        ),
        icon: 'euro',
      },
    ],
  ]);

  const filteredConfig = computed(() => {
    if (activeResultType.value === 'pv-project') {
      // Exclude the first child array to maintain order
      return config.value.slice(1);
    }
    // Return the whole config if tab is not 1
    return config.value;
  });

  const currentHeaders = computed(() =>
    activeResultType.value === 'pv-project'
      ? projectHeaders.value
      : clusterHeaders.value,
  );

  const slicePoints = computed(() =>
    activeResultType.value === 'pv-project' ? [1, 11] : [1, 10],
  );

  const splitTableData = computed(() => {
    const currentData =
      activeResultType.value === 'pv-project'
        ? projectData.value.results
        : clusterData.value.results;

    const firstTableKeys = currentHeaders.value
      .slice(0, slicePoints.value[0])
      .map((h) => h.key);
    const secondTableKeys = currentHeaders.value
      .slice(slicePoints.value[0], slicePoints.value[1])
      .map((h) => h.key);
    const thirdTableKeys = currentHeaders.value
      .slice(slicePoints.value[1])
      .map((h) => h.key);

    const processedData = currentData.map((item) => {
      const firstTableData = {};
      const secondTableData = {};
      const thirdTableData = {};

      Object.entries(item).forEach(([key, value]) => {
        if (firstTableKeys.includes(key)) {
          firstTableData[key] = value;
        } else if (secondTableKeys.includes(key)) {
          secondTableData[key] = value;
        } else if (thirdTableKeys.includes(key)) {
          thirdTableData[key] = value;
        }
      });

      firstTableData.id = item.id;
      firstTableData.cluster_projects = item.cluster_projects;
      firstTableData.is_manual = item.is_manual;
      secondTableData.id = item.id;
      thirdTableData.id = item.id;

      return {
        firstTable: firstTableData,
        secondTable: secondTableData,
        thirdTable: thirdTableData,
      };
    });

    return {
      headers: {
        firstTable: currentHeaders.value.slice(0, slicePoints.value[0]),
        secondTable: currentHeaders.value.slice(
          slicePoints.value[0],
          slicePoints.value[1],
        ),
        thirdTable: currentHeaders.value.slice(slicePoints.value[1]),
      },
      data: {
        firstTable: processedData.map((d) => d.firstTable),
        secondTable: processedData.map((d) => d.secondTable),
        thirdTable: processedData.map((d) => d.thirdTable),
      },
    };
  });

  function getReviewStateStyle(reviewState) {
    const reviewStateMap = {
      TODO: {
        icon: 'build_circle',
        color: 'text-spot-warning',
        label: 'Prüfung ausstehend',
      },
      YES: {
        icon: 'check_circle',
        color: 'text-spot-success',
        label: 'Fortführung',
      },
      NO: {
        icon: 'cancel',
        color: 'text-spot-error',
        label: 'Abbruch/nicht relevant',
      },
    };

    return reviewStateMap[reviewState];
  }

  return { tabs, filteredConfig, splitTableData, getReviewStateStyle };
};

import building from '@/apps/features/map/layer-config/building';
import environment from '@/apps/features/map/layer-config/environment';
import baseData from '@/apps/features/map/layer-config/base-data';
import externalProject from '@/apps/usecase-2/map/layer-config/external-project';
import potentialAnalysis from '@/apps/usecase-2/map/layer-config/potential-analysis';
import areaQualification from '@/apps/features/map/layer-config/area-qualification';
import marker from '@/apps/features/map/layer-config/marker';
import road from '@/apps/features/map/layer-config/road';
import existingPowerPlants from '@/apps/features/map/layer-config/existing-power-plants';
import administrativLevels from '@/apps/features/map/layer-config/administrativ-levels';
import planningMapPvFree from '@/apps/features/map/layer-config/planning-map-pv-free';
import cluster from '@/apps/usecase-2/map/layer-config/cluster';

export default {
  ...planningMapPvFree,
  ...cluster,
  ...road,
  ...areaQualification,
  ...building,
  ...existingPowerPlants,
  ...environment,
  ...externalProject,
  ...potentialAnalysis,
  ...administrativLevels,
  ...baseData,
  ...marker,
};

<template>
  <div class="w-full h-screen relative flex">
    <LayerPanel
      v-if="loaded && !mapStore.searchResults"
      v-show="mapStore.showMainMenu"
      :map-store
    >
      <template #layer-toggles="slotProps">
        <RestrictionFilterToggle v-bind="slotProps" />
      </template>
    </LayerPanel>
    <SlideContainer
      v-model="mapStore.filterContainerOpen"
      title="Filter"
      :prevent-close-from-outside="true"
    >
      <RestrictionFilter
        v-if="mapStore.activeFilterId === 'restriction'"
        :map-store
      />

      <ResultsSlideContainerFilter
        v-else-if="mapStore.activeFilterId === 'map'"
        :municipalities
        :results-store
        :filter-analysis-in-map="mapStore.selectedAnalysis.id"
        @update:filtered-ids="applyProjectFilterOnMap($event)"
      />
    </SlideContainer>
    <div class="mapView h-full relative w-full">
      <PvProjectForm v-if="mapStore.showCustomPvProjectForm" :map-store />
      <MeasuringToolForm v-else-if="mapStore.showMeasureToolForm" :map-store />
      <ExternalProjectForm
        v-else-if="mapStore.showExternalProjectForm"
        :map-store
      />
      <ClusterForm v-else-if="mapStore.showClusterForm" :map-store />
      <EstateSearchForm v-else-if="mapStore.showEstateSearchForm" :map-store />
      <template v-if="loaded">
        <BaseMap
          :map-store
          :selected-analysis-id="analysisSelected"
          :layer-config="LayerConfig"
          :panel-objects="panelObjects"
          :fly-to-function="flyToBBox"
          :extend-left-click-functionality="highlightPvProject"
          @map-style-loaded-signal="onMapStyleLoad()"
        />
        <div class="top-controls absolute w-full">
          <ControlBar :map-store />
          <AnalysisSelector
            :analysis-items="analyses"
            :selected-analysis-id="analysisSelected"
            :map-store
            :disabled-callback="(item) => item.progress !== 'success'"
            @select-analysis="analysisSelected = $event"
          >
            <template #before-title>
              <ButtonEl
                icon="filter_alt"
                class="w-10 h-10 rounded-sm standard-elevation-0-dark"
                :color="mapStore.activeFilterId === 'map' ? 'color1' : 'color2'"
                @click="mapStore.toggleFilterContainer('map')"
              />
            </template>
            <template #title-selected>
              <div class="caption-1 whitespace-nowrap flex">
                Potenzialanalyse
              </div>
              <h5 class="whitespace-nowrap max-w-60 truncate">
                {{
                  analyses.find((item) => item.id === analysisSelected)?.title
                }}
              </h5>
            </template>
            <template #additional-info="{ item }">
              <div class="border-b border-line-neutral w-full"></div>
              <div class="flex flex-col gap-1.5 w-full text-neutral">
                <div class="flex justify-between text-title-neutral">
                  <h6>Manuelle Analyse:</h6>
                  <h6 class="text-core-dark">
                    {{ item.data.is_manual ? ' Ja' : ' Nein' }}
                  </h6>
                </div>
                <div
                  class="flex justify-between items-center text-title-neutral gap-1.5"
                >
                  <h6>Status:</h6>
                  <div
                    class="flex p-1 gap-1 rounded-[4px]"
                    :class="getListProgressIcon(item.data.progress).color"
                  >
                    <IconWrapper
                      :icon="getListProgressIcon(item.data.progress).icon"
                      :size="16"
                    />
                    <h6>{{ getListProgressIcon(item.data.progress).label }}</h6>
                  </div>
                </div>
              </div>
            </template>
          </AnalysisSelector>
          <LegendControls
            :map-store
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
        </div>
        <div class="bottom-controls absolute w-full">
          <MapControls :map-store v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import LayerPanel from '@/apps/features/map/layer-panel/LayerPanel.vue';
import LayerConfig from '@/apps/usecase-2/map/layer-config';
import BaseMap from '@/apps/features/map/BaseMap.vue';
import ControlBar from '@/apps/features/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/features/map/control-options/components/LegendControls.vue';
import MapControls from '@/apps/features/map/control-options/components/MapControls.vue';
import { panelObjects } from '@/apps/usecase-2/map/info-panel/Info-panel-objects';
import { fitBBox, getMap } from '@/mapbox/main';
import PvProjectForm from '@/apps/usecase-2/map/PvProjectForm.vue';
import MeasuringToolForm from '@/apps/usecase-2/map/MeasuringToolForm.vue';
import ExternalProjectForm from '@/apps/usecase-2/map/ExternalProjectForm.vue';
import ClusterForm from '@/apps/usecase-2/map/ClusterForm.vue';
import EstateSearchForm from '@/apps/usecase-2/map/EstateSearchForm.vue';
import AnalysisSelector from '@/apps/features/map/control-options/components/AnalysisSelector.vue';
import { useMapStore } from './map-store';
import axios from '@/utils/axiosHelper';
import { useRoute, useRouter } from 'vue-router';
import RestrictionFilter from '@/apps/usecase-2/map/RestrictionFilter.vue';
import { useToastStore } from '@/stores/toast-store';
import {
  LAYER_KEY__PV_PROJECT,
  LAYER_KEY__PV_PROJECT_CONNECTION,
  LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
  LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
  LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
} from '@/apps/usecase-2/map/layer-config/potential-analysis';
import RestrictionFilterToggle from '@/apps/usecase-2/map/layer-panel/RestrictionFilterToggle.vue';
import SlideContainer from '@/components/SlideContainer.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import ResultsSlideContainerFilter from '@/apps/usecase-2/result-evaluation/components/ResultsSlideContainerFilter.vue';
import { useSyncGeoMapSelection } from '@/composables/syncGeoMapSelection';

const { municipalities } = await useSyncGeoMapSelection();

const toastStore = useToastStore();
const mapStore = useMapStore();

const router = useRouter();
const route = useRoute();

const showLegend = ref(false);
const loaded = ref(false);

onMounted(() => {
  startFetchingAnalysis();
  initializeMap();
});

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
  mapStore.initializeMapActions();
}

async function flyToBBox() {
  let bbox;
  if (projectIdSelected.value) {
    const resp = await axios({
      method: 'GET',
      url: `/api/usecase-2/bbox-project/${projectIdSelected.value}/`,
    });
    bbox = resp.data.bbox;
    highlightFeaturesProject(projectIdSelected.value);
  } else {
    const resp = await axios({
      method: 'GET',
      url: `/api/usecase-2/bbox-analysis/${analysisSelected.value}/`,
    });
    bbox = resp.data.bbox;
  }
  if (bbox === null) {
    toastStore.showToast({
      color: 'error',
      message: 'Keine Potenzialflächen',
    });
  } else {
    fitBBox(bbox);
  }
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}

const analyses = ref([]);

async function fetchAnalyses() {
  const analysesData = await axios({
    method: 'GET',
    url: '/api/usecase-2/analysis/',
  });
  analyses.value = analysesData.data.results;
}

await fetchAnalyses();

const projectIdSelected = ref(Number(route.query.projectId) || null);
const analysisSelected = ref(
  Number(route.params.analysisId) || Number(analyses.value[0].id),
);

mapStore.selectedAnalysis = analyses.value.find(
  (s) => s.id === analysisSelected.value,
);

async function onMapStyleLoad() {
  await mapStore.fetchAndFilterBatteryOption();
}

let invokedInitialMapload = true;

watch(analysisSelected, async (newVal) => {
  // reset route query to prevent zoom to project on analysis change
  projectIdSelected.value = null;
  await router.push({
    name: 'mapView2',
    params: { analysisId: newVal },
  });
  mapStore.selectedAnalysis = analyses.value.find((s) => s.id === newVal);
  await flyToBBox();
  if (invokedInitialMapload === false) {
    await mapStore.fetchAndFilterBatteryOption();
  }
  invokedInitialMapload = false;
});
// ------------ Interval reference
const interval = ref();

function startFetchingAnalysis() {
  if (!interval.value) {
    interval.value = setInterval(() => {
      fetchAnalyses();
    }, 5000);
  }
}

function stopFetchingScenarios() {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
}

const highlightProject = {
  [LAYER_KEY__PV_PROJECT]: {
    paintProperty: ['fill-opacity'],
    property: 'id',
    opacity: 0.2,
  },
  [LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT]: {
    paintProperty: ['circle-opacity', 'circle-stroke-opacity'],
    property: 'project_id',
    opacity: 0.05,
  },
  [LAYER_KEY__PV_PROJECT_SUBSTATION_POINT]: {
    paintProperty: ['circle-opacity', 'circle-stroke-opacity'],
    property: 'project_id',
    opacity: 0.05,
  },
  [LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT]: {
    paintProperty: ['circle-opacity', 'circle-stroke-opacity'],
    property: 'project_id',
    opacity: 0.05,
  },
  [LAYER_KEY__PV_PROJECT_CONNECTION]: {
    paintProperty: ['line-opacity'],
    property: 'project_id',
    opacity: 0.1,
  },
};

function highlightFeaturesProject(projectId) {
  for (const [key, value] of Object.entries(highlightProject)) {
    value.paintProperty.forEach((p) => {
      getMap().setPaintProperty(key, p, [
        'case',
        ['==', ['get', value.property], projectId],
        1,
        value.opacity,
      ]);
    });
  }
}

function highlightPvProject(feature) {
  if (feature && feature.source === LAYER_KEY__PV_PROJECT) {
    highlightFeaturesProject(feature.properties.id);
  } else {
    for (const [key, value] of Object.entries(highlightProject)) {
      value.paintProperty.forEach((p) => {
        getMap().setPaintProperty(key, p, 1);
      });
    }
  }
}

function applyProjectFilterOnMap(ids) {
  mapStore.addFilter(
    [
      LAYER_KEY__PV_PROJECT_CONNECTION,
      LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
      LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
      LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
    ],
    ['in', ['get', 'project_id'], ['literal', ids]],
    'projectFilter',
  );
  mapStore.addFilter(
    [LAYER_KEY__PV_PROJECT],
    ['in', ['get', 'id'], ['literal', ids]],
    'projectFilter',
  );
}

onBeforeUnmount(() => {
  toastStore.hideToast();
  mapStore.showMainMenu = false;
  mapStore.mapActionActive = null;
  stopFetchingScenarios();
});

function getListProgressIcon(progress) {
  const progressMap = {
    success: {
      icon: 'task_alt',
      color: 'text-spot-success bg-fill-success',
      label: 'Erfolgreich',
    },
    in_progress: {
      icon: 'pending_actions',
      color: 'text-spot-warning bg-fill-warning',
      label: 'Projekt angelegt',
    },
    exited: {
      icon: 'cancel',
      color: 'text-spot-error bg-fill-error',
      label: 'Abbruch',
    },
  };

  return progressMap[progress];
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>

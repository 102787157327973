<template>
  <div
    :class="{ 'left-5': !showMainMenu, 'left-[400px]': showMainMenu }"
    class="bg-white rounded-md top-[80px] h-fit w-[360px] absolute z-10 p-2.5 flex flex-col gap-4 standard-elevation-2 overflow-y-auto hide-scrollbar"
  >
    <h5 class="text-title-neutral">Messwerkzeug</h5>

    <!-- Conditional Display Based on Measurement Values -->
    <div v-if="measureToolValue" class="flex flex-col gap-2.5">
      <h5
        class="flex gap-2 items-center justify-between text-color1 standard-elevation-0-dark rounded-sm p-2.5"
      >
        {{ measureToolValue }}
        <IconWrapper
          icon="copy_all"
          class="cursor-pointer"
          @click="copyToClipboard(measureToolValue)"
        />
      </h5>
    </div>
    <ButtonEl
      :full-width="true"
      text="Beenden"
      color="color2"
      variant="secondary"
      @click="abort"
    />
  </div>
</template>

<script setup>
import { draw } from '@/apps/features/map/draw/draw';
import { toRefs, watch } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { useToastStore } from '@/stores/toast-store';

const props = defineProps({
  mapStore: {
    type: Object,
  },
});

const { showToast, hideToast } = useToastStore();

const { measureToolValue, showMainMenu } = toRefs(props.mapStore);

function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
  showToast({
    color: 'color1',
    message: 'Wert wurde in die Zwischenablage kopiert.',
    timeout: 2000,
  });
}

function abort() {
  props.mapStore.resetMapAction(draw);
}

watch(
  () => measureToolValue.value,
  (val) => {
    if (val) {
      hideToast();
    } else {
      showToast({
        color: 'color1',
        message:
          'Entfernungen und Flächen können gemessen werden, indem eine Strecke oder Fläche auf der Karte durch Klicken erzeugt wird.',
        timeout: -1,
        clearable: false,
        maxWidth: '85%',
      });
    }
  },
  { immediate: true },
);
</script>

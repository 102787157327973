<template>
  <div class="flex justify-space-between">
    <ButtonEl text="Anwenden" @click="apply" />
    <ButtonEl
      text="Zurücksetzen"
      color="color2"
      variant="secondary"
      @click="reset"
    />
  </div>
  <ButtonEl text="Aus Analyse übernehmen" @click="applyFromAnalysis" />
  <v-list v-model:opened="open" class="standard-container hide-scrollbar">
    <v-list-group
      v-for="[key, value] of Object.entries(categoriesNamingMap)"
      :key="key"
      :value="value"
    >
      <template #activator="{ props: activatorProps }">
        <v-list-item
          v-bind="activatorProps"
          :title="value"
          :ripple="false"
          :class="[
            'hover:text-color1 px-0',
            { 'text-color1': open?.includes(value) },
          ]"
        />
      </template>
      <div class="px-1 flex flex-col gap-2.5">
        <CheckboxEl
          label="Zeige alle"
          :value="key"
          :model-value="areAllSelected(key).value"
          @click="toggleAll(key)"
        />
        <CheckboxEl
          v-for="sub of categoriesData.filter(
            (e) => e.restriction_category === key,
          )"
          :key="sub.restriction_sub_category"
          v-model="selected"
          :label="subCategoriesNamingMap[sub.restriction_sub_category]"
          :value="sub.restriction_sub_category"
        />
      </div>
    </v-list-group>
  </v-list>
</template>

<script setup>
import { usePotentialAnalysisStore } from '@/apps/usecase-2/potential-analysis/potential-analysis-store';
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { setFilter } from '@/mapbox/main';
import { LAYER_KEY__RESTRICTION } from '@/apps/features/map/layer-config/base-data';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import axios from '@/utils/axiosHelper';

const potentialStore = usePotentialAnalysisStore();

const props = defineProps({
  mapStore: {
    type: Object,
  },
});

await potentialStore.fetchRestrictions();

const { categoriesNamingMap, subCategoriesNamingMap, categoriesData } =
  storeToRefs(usePotentialAnalysisStore());

const selected = ref([]);
const open = ref();

const categoryToSubcategories = new Map();
categoriesData.value.forEach((item) => {
  if (!categoryToSubcategories.has(item.restriction_category)) {
    categoryToSubcategories.set(item.restriction_category, []);
  }
  categoryToSubcategories
    .get(item.restriction_category)
    .push(item.restriction_sub_category);
});

const areAllSelected = (key) =>
  computed(() => {
    const subcategories = categoryToSubcategories.get(key) || [];
    const selectedSet = new Set(selected.value);
    return subcategories.every((sub) => selectedSet.has(sub));
  });

function toggleAll(key) {
  const subcategories = categoryToSubcategories.get(key) || [];
  const allSelected = areAllSelected(key).value;
  if (allSelected) {
    selected.value = selected.value.filter(
      (sub) => !subcategories.includes(sub),
    );
  } else {
    selected.value = Array.from(new Set([...selected.value, ...subcategories]));
  }
}

function apply() {
  props.mapStore.addFilter(
    [LAYER_KEY__RESTRICTION],
    ['in', ['get', 'restriction_sub_category'], ['literal', selected.value]],
    'restrictionFilter',
  );
}

async function applyFromAnalysis() {
  const restrictionData = await axios({
    method: 'GET',
    url: `/api/usecase-2/restrictions-by-analysis/${props.mapStore.selectedAnalysis.id}`,
  });
  selected.value = restrictionData.data.sub_categories;
  apply();
}

function reset() {
  selected.value = [];
  props.mapStore.removeFilter([LAYER_KEY__RESTRICTION], 'restrictionFilter');
}
</script>

<style scoped lang="scss">
:deep(.v-list-item:hover) {
  .v-list-item__overlay {
    display: none;
  }
}
</style>

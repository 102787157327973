<template>
  <Transition name="slide">
    <div
      v-if="modelValue"
      ref="containerRef"
      class="slide-container standard-elevation-0-dark"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <div class="slide-header">
        <h4 v-if="title" class="text-title-color1">{{ title }}</h4>
        <IconWrapper
          icon="close"
          fill="text-color1"
          class="cursor-pointer"
          @click="closeContainer"
        />
      </div>
      <div class="slide-content hide-scrollbar">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, onBeforeUnmount, watch, toRefs } from 'vue';
import { onClickOutside } from '@vueuse/core';
import IconWrapper from './IconWrapper/IconWrapper.vue';
import { useMapStore } from '@/apps/usecase-2/map/map-store';

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  modalIsOpen: {
    type: Boolean,
    default: false,
  },
  preventCloseFromOutside: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
const containerRef = ref(null);
const mapStore = useMapStore();
const { activeFilterId } = toRefs(mapStore);

const closeContainer = () => {
  if (props.modalIsOpen) return;
  handleMouseLeave();
  emit('update:modelValue', false);
  activeFilterId.value = null;
};

onClickOutside(
  containerRef,
  props.preventCloseFromOutside ? () => {} : closeContainer,
);

const mainElement = ref(document.querySelector('html'));

function handleMouseEnter() {
  mainElement.value.style.overflow = 'hidden'; // Lock scroll
}

function handleMouseLeave() {
  mainElement.value.style.overflow = ''; // Unlock scroll
}

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      handleMouseEnter();
    }
  },
);

onBeforeUnmount(() => {
  // Ensure scroll is unlocked on unmount
  handleMouseLeave();
});
</script>

<style scoped>
.slide-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 440px;
  height: 100vh;
  background: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.slide-header {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Transition animations */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style>

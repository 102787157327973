<template>
  <TopHeaderBar />
  <div class="flex flex-col gap-5">
    <div class="flex items-center gap-4">
      <IconWrapper
        :size="64"
        :icon="IconEnergieEffizienz"
        fill="text-core-color2"
      />
      <h1 class="text-title-neutral">Auswertung</h1>
    </div>
    <div
      class="flex flex-col gap-5 bg-white rounded-xl p-5 standard-elevation-2 min-h-[calc(100vh-374px)]"
    >
      <div class="flex gap-2.5">
        <FormTabs
          :disable-transitions="true"
          :prevent-window-scroll="true"
          :tabs
          :hide-tab-windows="true"
          custom-element-tag="h4"
          @updated:tab="activeResultType = $event.id"
        />
        <ButtonEl
          icon="filter_alt"
          color="color2"
          variant="secondary"
          @click="toggleSlideContainer('Filter')"
        />
      </div>
      <div class="flex flex-col justify-between w-full gap-5">
        <div v-auto-animate class="flex flex-col gap-2.5">
          <div
            v-for="row in filteredConfig"
            :key="row"
            class="grid grid-flow-col auto-cols-fr gap-2.5"
          >
            <div
              v-for="item in row"
              :key="item"
              class="bg-white standard-elevation-0-dark rounded-lg p-2.5 flex items-center w-full body-1 gap-3"
            >
              <IconWrapper
                :size="32"
                :icon="item.icon"
                fill="text-title-neutral"
              />
              <div class="flex items-center justify-between w-full">
                <span class="pt-0.5 text-neutral">{{ item.label }}</span>
                <h4 class="text-title-neutral">
                  {{ toLocaleStr(item.value, 0, item.maxDigits ?? 1) }}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between gap-2.5">
          <InputEl
            :placeholder="
              activeResultType === 'pv-project'
                ? 'Nach Einzelprojekt oder Gemeinde suchen'
                : 'Nach Cluster oder Gemeinde suchen'
            "
            class="w-1/3"
            :suffix-icon="true"
            suffix="search"
            :suffix-left="true"
          />
        </div>

        <div
          class="grid auto-cols-auto grid-flow-col relative mb-[68px] standard-elevation-0-dark rounded-lg"
        >
          <VuetifyDataTable
            :total-items="resultsCountActive"
            :headers="splitTableData.headers.firstTable"
            :item-data="splitTableData.data.firstTable"
            :show-expand="false"
            :hide-footer="true"
            :fixed-header-height="108"
            :fixed-row-height="68"
            :set-page="page"
            :set-items-per-page="itemsPerPage"
            class="rounded-r-none w-[300px] rounded-bl-none shadow-none"
          >
            <template #name="{ item }">
              <div
                v-if="item.name"
                class="flex items-center justify-between gap-2"
              >
                <!-- name -->
                <div class="flex items-center gap-5">
                  <IconWrapper
                    type="round"
                    fill="text-title-neutral"
                    icon="all_out"
                  />
                  <div
                    class="whitespace-nowrap subtitle-2 text-core-dark pt-[3px]"
                  >
                    <template v-if="item.name.length > 15">
                      <ToolTip :clean-content="true" :slot-max-width="null">
                        <div>
                          {{ item.name.substring(0, 15) + '...' }}
                        </div>
                        <template #text>
                          <div
                            class="bg-white rounded-xs standard-elevation-0-dark flex justify-start items-center p-2.5"
                          >
                            <h6 class="text-core-dark">{{ item.name }}</h6>
                          </div>
                        </template>
                      </ToolTip>
                    </template>
                    <template v-else>
                      {{ item.name }}
                    </template>
                  </div>
                </div>

                <!-- tags -->
                <div class="flex items-center gap-1">
                  <template v-for="tag in tagConfig" :key="tag">
                    <ToolTip
                      v-if="item[tag.check]"
                      :clean-content="true"
                      :slot-max-width="null"
                      :offset="4"
                      :prevent-close-on-hover="true"
                    >
                      <div
                        class="cursor-pointer bg-white rounded-xs border border-line-disabled-neutral"
                      >
                        <IconWrapper fill="text-core-color1" :icon="tag.icon" />
                      </div>
                      <template #text>
                        <div
                          class="bg-white rounded-xs standard-elevation-0-dark flex justify-start items-center gap-2.5 p-2.5"
                        >
                          <h6 class="text-core-dark">
                            {{
                              tag.label +
                              (tag.id === 'cluster_projects' &&
                              item.cluster_projects[0]?.cluster_name
                                ? item.cluster_projects[0]?.cluster_name
                                : '')
                            }}
                          </h6>
                          <div
                            class="flex items-center gap-0.5 text-title-neutral"
                          >
                            <h6>Anzeigen</h6>
                            <IconWrapper :size="16" icon="open_in_new" />
                          </div>
                        </div>
                      </template>
                    </ToolTip>
                  </template>
                </div>
              </div>
            </template>
          </VuetifyDataTable>
          <div
            class="w-full overflow-x-auto hide-scrollbar second-table-custom"
          >
            <div class="left-inner-shadow"></div>
            <div class="right-inner-shadow"></div>
            <VuetifyDataTable
              :total-items="resultsCountActive"
              :headers="splitTableData.headers.secondTable"
              :item-data="splitTableData.data.secondTable"
              :show-expand="false"
              :hide-footer="true"
              :fixed-header-height="108"
              :fixed-row-height="68"
              :set-page="page"
              :set-items-per-page="itemsPerPage"
              class="rounded-none w-full shadow-none"
            >
              <template #count_projects_enbw="{ item }">
                <div class="flex items-center gap-1">
                  <template
                    v-for="tag in countProjectsTagConfig(item)"
                    :key="tag"
                  >
                    <ToolTip
                      :clean-content="true"
                      :slot-max-width="null"
                      :offset="2"
                    >
                      <div
                        class="bg-white rounded-xs border border-line-disabled-neutral flex items-center gap-2 p-1"
                      >
                        <IconWrapper
                          fill="text-title-neutral"
                          :icon="tag.icon"
                        />
                        <span
                          class="text-core-dark subtitle-2 pt-[3px] min-w-[12px] text-right pr-0.5"
                          >{{ tag.value || 0 }}</span
                        >
                      </div>
                      <template #text>
                        <div
                          class="bg-white rounded-xs standard-elevation-0-dark flex justify-start items-center p-2"
                        >
                          <h6 class="text-core-dark">{{ tag.label }}</h6>
                        </div>
                      </template>
                    </ToolTip>
                  </template>
                </div>
              </template>
              <template #is_type="{ item }">
                {{
                  item.is_quickcheck
                    ? 'Quick-Check'
                    : item.is_manual
                      ? 'Bottom-Up'
                      : 'Top-Down'
                }}
              </template>

              <template #municipalities="{ item }">
                <div
                  v-if="item.municipalities.length"
                  class="flex items-center justify-between"
                >
                  <span class="whitespace-nowrap">
                    {{
                      `${item.municipalities.length} ${item.municipalities.length > 1 ? 'Gemeinden' : 'Gemeinde'}, ${item.municipalities[0].name.length > 10 ? item.municipalities[0].name.substring(0, 10) + '...' : item.municipalities[0].name}`
                    }}
                  </span>
                  <ToolTip :clean-content="true" :slot-max-width="null">
                    <template #default>
                      <IconWrapper
                        type="round"
                        fill="text-core-color1"
                        icon="more"
                        class="cursor-pointer"
                      />
                    </template>
                    <template #text>
                      <div
                        class="flex flex-col rounded-lg overflow-hidden standard-elevation-0-dark border border-line-disabled-neutral"
                      >
                        <div
                          class="grid grid-flow-col auto-cols-fr whitespace-nowrap bg-white"
                        >
                          <h6
                            v-for="headerItem in [
                              'Regierungsbezirk',
                              'Landkreis',
                              'Gemeinde',
                            ]"
                            :key="headerItem"
                            class="text-title-neutral p-2.5"
                          >
                            {{ headerItem }}
                          </h6>
                        </div>
                        <div>
                          <div
                            v-for="(municipality, index) in item.municipalities"
                            :key="municipality"
                            :class="[
                              'grid grid-flow-col auto-cols-fr',
                              index % 2 === 0 ? 'bg-subtle' : 'bg-white',
                            ]"
                          >
                            <span
                              v-for="key in [
                                'administrative_district',
                                'county',
                                'name',
                              ]"
                              :key="key"
                              class="p-2.5 body-3 text-core-dark"
                            >
                              {{ municipality[key] }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </ToolTip>
                </div>
                <div v-else>Keine Gemeinden</div>
              </template>
              <template #potential_area_m2="{ item }">
                {{ toLocaleStr(item.potential_area_m2 / 10000) + ' ha' }}
              </template>
              <template #area_enbw_m2="{ item }">
                {{ toLocaleStr(item.area_enbw_m2 / 10000) + ' ha' }}
              </template>
              <template #count_estates="{ item }">
                {{ item.count_estates.toLocaleString('de-DE') }}
              </template>
              <template #power_kw="{ item }">
                {{ toLocaleStr(item.power_kw) + ' MW' }}
              </template>
              <template #power_enbw_kw="{ item }">
                {{ toLocaleStr(item.power_enbw_kw) + ' MW' }}
              </template>
              <template #connection_length_m="{ item }">
                {{ toLocaleStr(item.connection_length_m) + ' m' }}
              </template>
              <template #connection_length_enbw_m="{ item }">
                {{ toLocaleStr(item.connection_length_enbw_m) + ' m' }}
              </template>
              <template #battery_capacity_kw="{ item }">
                {{ toLocaleStr(item.battery_capacity_kw) + ' MW' }}
              </template>
              <template #battery_power_enbw_kw="{ item }">
                {{ toLocaleStr(item.battery_power_enbw_kw) + ' MW' }}
              </template>
              <template #battery_capacity_enbw_kw="{ item }">
                {{ toLocaleStr(item.battery_capacity_enbw_kw) + ' MW' }}
              </template>
              <template #specific_investment_eur_kw="{ item }">
                {{ toLocaleStr(item.specific_investment_eur_kw) + ' €/kW' }}
              </template>
              <template #specific_investment_enbw_eur_kw="{ item }">
                {{
                  toLocaleStr(item.specific_investment_enbw_eur_kw) + ' €/kW'
                }}
              </template>
              <template #voltage_level="{ item }">
                {{ item.voltage_level === '0' ? 'HS' : 'MS' }}
              </template>
              <template #total_investment_cost_eur="{ item }">
                {{
                  toLocaleStr(item.total_investment_cost_eur, 0, 1) + ' Mio. €'
                }}
              </template>
              <template #investment_cost_enbw_eur="{ item }">
                {{
                  toLocaleStr(item.investment_cost_enbw_eur, 0, 1) + ' Mio. €'
                }}
              </template>
            </VuetifyDataTable>
          </div>
          <VuetifyDataTable
            :total-items="resultsCountActive"
            :headers="splitTableData.headers.thirdTable"
            :item-data="splitTableData.data.thirdTable"
            :set-page="page"
            :set-items-per-page="itemsPerPage"
            :show-expand="false"
            :fixed-header-height="108"
            :fixed-row-height="68"
            class="rounded-l-none w-min third-table shadow-none"
            :prevent-immediate-update-options="true"
            @update:options="getEvaluationResults($event, activeResultType)"
          >
            <template #utility_value="{ item }">
              <div
                class="bg-[#968F89] flex items-center justify-center rounded-xs px-1.5 pt-[3px] pb-1 w-fit mx-auto"
              >
                <h4 class="text-white">{{ item.utility_value }}</h4>
              </div>
            </template>
            <template #review_state="{ item }">
              <ToolTip
                v-if="item.review_state"
                :tooltip-text="getReviewStateStyle(item.review_state).label"
                class="mx-auto"
              >
                <IconWrapper
                  type="round"
                  :fill="getReviewStateStyle(item.review_state).color"
                  :icon="getReviewStateStyle(item.review_state).icon"
                />
              </ToolTip>
            </template>
            <template #is_favorite="{ item }">
              <ToolTip
                v-if="item.is_favorite"
                tooltip-text="Prioritätsfläche"
                class="mx-auto"
              >
                <IconWrapper type="round" fill="text-color1" icon="stars" />
              </ToolTip>
              <div v-else></div>
            </template>
            <template #functions="{ item }">
              <div class="flex justify-end items-center gap-4 w-fit">
                <IconWrapper
                  type="round"
                  :size="32"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  icon="map"
                  @click="navigate(item)"
                />
                <IconWrapper
                  type="round"
                  :size="32"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  icon="arrow_forward"
                  @click="navigateToDetail(item.id)"
                />
              </div>
            </template>
          </VuetifyDataTable>
        </div>

        <div class="flex items-center justify-between gap-2.5">
          <ExportCsv
            :data="projectData.results"
            :filename="'auswertung_' + activeResultType"
          />
          <h6 v-if="activeResultType === 'cluster'" class="text-neutral">
            *Werte in der Tabelle beziehen sich auf den Anteil von PV-Projekten
            der EnBW innerhalb des Clusters.
          </h6>
        </div>
      </div>
    </div>
  </div>

  <SlideContainer
    v-model="slideContainerOpen"
    :title="slideContainerTitle"
    :modal-is-open
  >
    <ResultsSlideContainerFilter
      v-if="slideContainerTitle === 'Filter'"
      :municipalities
      :results-store
      @update:modal-is-open="modalIsOpen = $event"
    />
  </SlideContainer>
</template>

<script setup>
/*
TODO: Wir sollten hier die pagination mit den requests aus dem Backend
 verknüpfen. Haben wir in den Usecase 1 Ansichten nicht gemacht,
 soweit ich weiß und müssten wir noch nachziehen. Das Filterset im Design
 Müsste sich ebenfalls auf die Ergebnisse auswirken. Die Textsuche nach Namen/
 Gemeinde wird nur auf Ergebnisse im Frontend angewand.

 Es fehlen im Backend: Investkosten, status, Spannungsebene, is in cluster,
 is bottom up
*/
import IconEnergieEffizienz from '@/assets/icons/custom/misc/IconEnergieEffizienz.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/input/InputEl.vue';
import SlideContainer from '@/components/SlideContainer.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import { ref, toRefs, markRaw, watch } from 'vue';
import { useResultEvaluationStore } from './result-evaluation-store';
import { toLocaleStr } from '@/utils/formatUtils';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import ResultsSlideContainerFilter from './components/ResultsSlideContainerFilter.vue';
import { useSyncGeoMapSelection } from '@/composables/syncGeoMapSelection';
import { useRouter } from 'vue-router';
import ExportCsv from '@/components/ExportCsv.vue';
import IconMapMarkRounded from '@/assets/icons/custom/map-actions/IconMapMarkRounded.vue';
import { navigateToMap } from '@/apps/usecase-2/utils';
import { useResultOverviewData } from './configs/useResultsOverviewData';
import IconEnBWLogoSmall from '@/assets/icons/custom/misc/IconEnBWLogoSmall.vue';

const { municipalities } = await useSyncGeoMapSelection();

const resultsStore = useResultEvaluationStore();
const { getEvaluationResults, initResults } = resultsStore;
await initResults();
const {
  page,
  itemsPerPage,
  projectData,
  activeResultType,
  resultsCountActive,
} = toRefs(resultsStore);
const { tabs, filteredConfig, splitTableData, getReviewStateStyle } =
  useResultOverviewData(resultsStore);

const router = useRouter();

const modalIsOpen = ref(false);

const slideContainerOpen = ref(false);
const slideContainerTitle = ref(null);

function toggleSlideContainer(title) {
  slideContainerOpen.value = true;
  slideContainerTitle.value = title;
}

function navigateToDetail(id) {
  const params =
    activeResultType.value === 'cluster'
      ? { clusterId: id }
      : { projectId: id };

  const name =
    activeResultType.value === 'cluster'
      ? 'UseCase2ClusterResultOverview'
      : 'UseCase2ProjectResultOverview';

  router.push({
    name,
    params,
  });
}

function countProjectsTagConfig(item) {
  return [
    {
      label: 'EnBW-Projekte',
      icon: markRaw(IconEnBWLogoSmall),
      value: item.count_projects_enbw,
    },
    {
      label: 'Externe Projekte',
      icon: 'device_hub',
      value: item.count_projects_external,
    },
  ];
}

const tagConfig = ref([
  {
    id: 'cluster_projects',
    label: 'Enthalten in',
    icon: 'bubble_chart',
    check: 'cluster_projects.length',
  },
  {
    id: 'is_manual',
    label: 'Manuell in Karte erstellt',
    icon: markRaw(IconMapMarkRounded),
    check: 'is_manual',
  },
]);

function navigate(item) {
  const project = projectData.value.results.find((e) => e.id === item.id);
  navigateToMap(router, project.analysis_id, project.id);
}

watch(activeResultType, () => {
  page.value = 1;
  itemsPerPage.value = 10;
  getEvaluationResults(null, activeResultType.value);
});
</script>

<style scoped lang="scss">
@use '@/assets/styles';

:deep(.third-table) {
  position: unset;
  .vuetify-data-table-footer {
    position: absolute;
    background-color: white;
    right: 0;
    bottom: -68px;
    width: 100%;
    @apply rounded-b-lg;
    @extend .standard-elevation-0-dark;
  }
}

.second-table-custom {
  position: relative;

  .left-inner-shadow {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background: linear-gradient(
      to right,
      rgba(126, 117, 112, 0.2),
      transparent
    );
    z-index: 1;
  }

  .right-inner-shadow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background: linear-gradient(to left, rgba(126, 117, 112, 0.2), transparent);
    z-index: 1;
  }
}
</style>

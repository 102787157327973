import { fetchData } from './utils';
import { mergeFeatureDataAndLayerConfig } from '@/apps/features/map/info-panel/panel-data/panel-data-calculator';
import axios from '@/utils/axiosHelper';

export class SimplePanel {
  metaData = null;

  constructor(url, layerIds) {
    this.url = url;
    this.layerIds = layerIds;
  }

  async requestFeatureData(feature, mapStore) {
    const featureId = feature.properties.id;
    const layerId = feature.layer.id;

    if (!mapStore.metaDataLayers[layerId]) {
      const metaData = {};
      const options = await axios({
        url: this.url,
        method: 'OPTIONS',
      });

      const metaDataResponse = options.data.actions.POST;

      for (const [key, value] of Object.entries(metaDataResponse)) {
        if (value.type === 'nested object') {
          for (const [keyNested, valueNested] of Object.entries(
            value.children,
          )) {
            metaData[keyNested] = valueNested;
          }
        } else {
          metaData[key] = value;
        }
      }
      mapStore.setLayerMetaData(layerId, metaData);
    }
    const resp = await fetchData(`${this.url}${featureId}/`, mapStore);

    if (resp === null) return resp;

    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(featureData, newScenarioYear, mapStore) {
    const panelConfig = mapStore.layerConfigs[featureData.layerId].sidebar;
    const metaData = mapStore.metaDataLayers[featureData.layerId];
    return mergeFeatureDataAndLayerConfig(
      featureData,
      null,
      null,
      panelConfig,
      null,
      metaData,
    );
  }
}

<template>
  <div
    :class="{ 'left-5': !showMainMenu, 'left-[400px]': showMainMenu }"
    class="bg-white rounded-[8px] top-[80px] h-fit w-[360px] absolute z-10 p-2.5 flex flex-col gap-2.5 standard-elevation-2 overflow-y-auto hide-scrollbar"
  >
    <h5 class="text-title-neutral">Projektgebiet anlegen</h5>

    <FormValidator v-model="isProjectNameValid">
      <InputEl
        v-model="projectName"
        label="Bezeichnung"
        placeholder="Bitte eingeben"
        data-test="location-name"
        :rules="{ unique: [potentialsNames], required: true }"
      />
    </FormValidator>

    <div
      class="flex flex-col gap-2.5 standard-elevation-0-dark p-2.5 rounded-[6px] mt-2"
    >
      <h5 class="text-color1 flex items-center">Flurstücke selektieren</h5>

      <div
        class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
      >
        <span class="caption-1">Anzahl Flurstücke</span>
        <h6>
          {{
            estateIdsSelected.size
              ? estateIdsSelected.size.toLocaleString('de-DE')
              : 0
          }}
        </h6>
      </div>

      <div
        class="text-neutral flex flex-col items-center gap-0.5 standard-elevation-0-dark rounded-xs p-1.5"
      >
        <span class="caption-1">Amtliche Fläche</span>
        <h6>
          {{
            estatesSelectedArea
              ? estatesSelectedArea.toLocaleString('de-DE')
              : 0
          }}
          m²
        </h6>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2.5">
      <ButtonEl
        :full-width="true"
        color="color2"
        text="Abbrechen"
        @click="abort"
      />

      <ButtonEl
        :full-width="true"
        text="Konfiguration"
        icon="settings_input_component"
        :icon-right="true"
        :disabled="
          !potentialsData ||
          !isProjectNameValid ||
          !metaData ||
          !estateIdsSelected.size
        "
        class="pr-4"
        @click="showConfigDialog = true"
      />

      <VuetifyDialog
        v-model="showConfigDialog"
        :full-screen="true"
        :persistent="true"
        :hide-close-button="true"
        title="Konfiguration der Bottom-Up-Potenzialanalyse"
      >
        <template #content>
          <div class="flex flex-col gap-5">
            <div class="flex justify-space-between">
              <div class="flex gap-2.5 items-end">
                <DropDown
                  v-model="selectedPotential"
                  label="Voreinstellung"
                  placeholder="Bitte wählen"
                  :items-data="sortedPotentialsData"
                  items-data-key="title"
                  value="id"
                  class="min-w-[250px] w-fit"
                  :disabled="isQuickcheck || isDefault"
                />

                <div
                  v-if="selectedPotential"
                  class="caption-1 bg-fill-success text-spot-success p-2.5 flex gap-1.5 justify-start items-center h-10"
                >
                  <IconWrapper icon="info" />
                  <span class="pt-0.5">{{ messageForNewSet }}</span>
                </div>
              </div>
              <div class="flex justify-start">
                <ButtonEl
                  text="Quickcheck"
                  variant="secondary"
                  color="color2"
                  :disabled="isDefault"
                  @click="startQuickcheck"
                />
                <ButtonEl
                  text="Neu erstellen"
                  variant="secondary"
                  class="ml-5"
                  color="color2"
                  :disabled="isQuickcheck"
                  @click="startDefault"
                />
              </div>
            </div>

            <PotentialAnalysisTabsWrapper
              :form-data="{ id: selectedPotential, title: projectName }"
              :hide-tab-at-index="0"
              :meta-data
              :tabs-window-height-substract="335"
              :loading-spinner-height-substract="276"
              :disable-tab-indexes
              @update:potential-analysis="potentialAnalysis = $event"
              @update:is-valid="isProjectValid = $event"
            />
          </div>
        </template>
        <template #actions>
          <div class="flex gap-2.5 justify-between w-full">
            <ButtonEl
              text="Abbrechen"
              variant="secondary"
              color="color2"
              @click="showAbortDialog = true"
            />
            <ButtonEl
              text="Speichern & Berechnung starten"
              icon="table_view"
              class="pl-4"
              :disabled="!isProjectValid"
              :is-loading="loading"
              @click="save"
            />
          </div>
        </template>
      </VuetifyDialog>
    </div>
  </div>

  <!-- Abort Dialog -->
  <VuetifyDialog
    v-if="showAbortDialog"
    v-model="showAbortDialog"
    :persistent="true"
    :hide-close-button="true"
    title="Konfiguration wirklich abbrechen?"
  >
    <template #content>
      <span>Die Bottom-Up-Potentizialanalyse wird nicht gespeichert.</span>
    </template>
    <template #actions>
      <ButtonEl
        text="Zurück"
        color="color2"
        variant="secondary"
        @click="showAbortDialog = false"
      />
      <ButtonEl text="Abbrechen bestätigen" color="warning" @click="abort" />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { draw } from '@/apps/features/map/draw/draw';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import PotentialAnalysisTabsWrapper from '../potential-analysis/components/PotentialAnalysisTabsWrapper.vue';
import { usePotentialAnalysisStore } from '../potential-analysis/potential-analysis-store';
import DropDown from '@/components/DropDown/DropDown.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { usePotentialAnalysis } from '../potential-analysis/composables/use-potential-analysis';
import { useToastStore } from '@/stores/toast-store';
import FormValidator from '@/components/FormValidator.vue';
import QuickCheckAnalysisConfig from '@/apps/usecase-2/potential-analysis/quick-check-analysis-config';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { showMainMenu, estateIdsSelected, estatesSelectedArea } = toRefs(
  props.mapStore,
);

const { showToast, hideToast } = useToastStore();

const showConfigDialog = ref(false);
const showAbortDialog = ref(false);
const projectName = ref();
const isProjectNameValid = ref(false);
const isProjectValid = ref(false);
const selectedPotential = ref(null);

const disableTabIndexes = computed(() => {
  let disabledIndexes = [0, 1, 2, 3, 4, 5];
  if (isQuickcheck.value) disabledIndexes = [0, 1, 2, 3];
  if (selectedPotential.value || isDefault.value) disabledIndexes = null;
  return disabledIndexes;
});

const {
  create,
  getPotentials,
  fetchMetaData,
  potentialsData,
  potentialAnalysis,
  metaData,
  potentialsNames,
  loading,
} = usePotentialAnalysis();

onMounted(async () => {
  // reset estates
  estateIdsSelected.value = new Set();
  estatesSelectedArea.value = null;

  await getPotentials();
  await fetchMetaData();
  await usePotentialAnalysisStore().fetchRestrictions();
});

async function save() {
  potentialAnalysis.value.estates = Array.from(estateIdsSelected.value);
  potentialAnalysis.value.is_manual = true;
  potentialAnalysis.value.is_quickcheck = isQuickcheck;
  const success = await create(false);
  if (success) {
    showConfigDialog.value = false;
    abort(false);
  }
}

function abort(setHideToast = true) {
  showAbortDialog.value = false;
  props.mapStore.resetMapAction(draw, setHideToast);
}

const messageForNewSet =
  'Geladene Werte können angepasst werden. Dabei wird ein neues Set erzeugt.';

const sortedPotentialsData = computed(() => {
  return [...potentialsData.value].sort((a, b) =>
    a.title.localeCompare(b.title),
  );
});

watch(
  () => estateIdsSelected.value.size,
  (val) => {
    if (val > 0) {
      hideToast();
    } else {
      showToast({
        color: 'color1',
        message: 'Bitte wählen Sie ein oder mehrere Flurstücke.',
        timeout: -1,
        clearable: false,
      });
    }
  },
  { immediate: true },
);

// ---------- Quickcheck and default
const isDefault = ref(false);
const isQuickcheck = ref(false);

function startQuickcheck() {
  for (const [key, value] of Object.entries(QuickCheckAnalysisConfig)) {
    potentialAnalysis.value[key] = value;
  }
  isQuickcheck.value = true;
}

function startDefault() {
  isDefault.value = true;
  selectedPotential.value = null;
}
</script>
